import React from 'react';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.min.css';
import './assets/css/main.css';
import AOS from 'aos';
import GLightbox from 'glightbox';
import heroImg from './assets/img/hero-img.png';
import aboutCompany1 from './assets/img/about-company-1.jpg';
import aboutCompany2 from './assets/img/about-company-2.jpg';
import aboutCompany3 from './assets/img/about-company-3.jpg';
import QRCodeSvg from './QrCodeSvg';

import { Link, NavLink } from "react-router-dom";
import LanguageSwitcher from 'components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { t } = useTranslation();

    React.useEffect(() => {
        const images = [
            heroImg,
            aboutCompany1,
            aboutCompany2,
            aboutCompany3,
        ];
        preloadImages(images);
        initializeCustomScripts();
    }, []);

    const preloadImages = (imagePaths) => {
        imagePaths.forEach((path) => {
            const img = new Image();
            img.src = path;
        });
    }

    const initializeCustomScripts = () => {
        toggleScrolled();
        document.addEventListener('scroll', toggleScrolled);
        window.addEventListener('load', toggleScrolled);

        const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
        if (mobileNavToggleBtn) {
            mobileNavToggleBtn.addEventListener('click', mobileNavToogle);
        }

        document.querySelectorAll('#navmenu a').forEach(navmenu => {
            navmenu.addEventListener('click', () => {
                if (document.querySelector('.mobile-nav-active')) {
                    mobileNavToogle();
                }
            });
        });

        document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
            navmenu.addEventListener('click', (e) => {
                e.preventDefault();
                toggleDropdown(navmenu);
            });
        });

        const preloader = document.querySelector('#preloader');
        if (preloader) {
            window.addEventListener('load', () => {
                preloader.remove();
            });
        }

        const scrollTop = document.querySelector('.scroll-top');
        if (scrollTop) {
            scrollTop.addEventListener('click', (e) => {
                e.preventDefault();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }

        window.addEventListener('load', toggleScrollTop);
        document.addEventListener('scroll', toggleScrollTop);

        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false,
        });

        GLightbox({
            selector: '.glightbox',
        });

        document.querySelectorAll('.faq-item h3, .faq-item .faq-toggle').forEach((faqItem) => {
            faqItem.addEventListener('click', () => {
                faqItem.parentNode.classList.toggle('faq-active');
            });
        });
    }

    const toggleScrolled = () => {
        const selectBody = document.querySelector('body');
        const selectHeader = document.querySelector('#header');
        if (!selectHeader) return;
        if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
        window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    }

    const mobileNavToogle = () => {
        document.querySelector('body').classList.toggle('mobile-nav-active');
        const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
        if (mobileNavToggleBtn) {
            mobileNavToggleBtn.classList.toggle('bi-list');
            mobileNavToggleBtn.classList.toggle('bi-x');
        }
    }

    const toggleDropdown = (element) => {
        element.parentNode.classList.toggle('active');
        element.parentNode.nextElementSibling.classList.toggle('dropdown-active');
    }

    const toggleScrollTop = () => {
        const scrollTop = document.querySelector('.scroll-top');
        if (scrollTop) {
            window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
        }
    }

    return (
        <div className="index-page">
            <header id="header" className="header d-flex align-items-center sticky-top">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
                    <a href="https://www.qr.ordonovus.com/" className="logo d-flex align-items-center">
                        <h1 className="sitename">
                            <span>Qr</span>Codes
                        </h1>
                    </a>
                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><a href="#hp" className="active">{t("frontpage")}</a></li>
                            <li><a href="#about">{t("about_product")}</a></li>
                            <li><a href="#services">{t("services")}</a></li>
                            <li><a href="#features">{t("features")}</a></li>
                            <li><a href="#pricing">{t("pricing")}</a></li>
                            <li><a href="#contact">{t("contact")}</a></li>
                            <li>
                                <NavLink className="nav-link" to="/login">
                                    {t("login")}
                                </NavLink></li>
                            <li>
                                <LanguageSwitcher />
                            </li>
                        </ul>
                        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                    </nav>
                </div>
            </header>

            <main className="main">
                {/* Hero Section */}
                <section id="hp" className="hp section light-background">
                    <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                        <div className="row gy-5">
                            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                                <h2>Qr Codes</h2>
                                <p>{t("sub_tittle_text")}</p>
                                <div className="d-flex">

                                    <NavLink className="btn-get-started" to="/register">
                                        {t("get_started")}
                                    </NavLink>


                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        float: 'right',
                                        padding: '20px',
                                        width: '100%',  // Adjust width as needed
                                        height: '100%'  // Adjust height as needed
                                    }}
                                >
                                    <div
                                        style={{
                                            float: 'right',
                                            backgroundColor: 'white',
                                            padding: '20px',
                                            border: '10px solid black',
                                            borderRadius: '10px',
                                            display: 'inline-block'
                                        }}
                                    >
                                        <QRCodeSvg className="qr-code" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="icon-boxes position-relative" data-aos="fade-up" data-aos-delay="200">
                        <div className="container position-relative">
                            <div className="row gy-4 mt-5">
                                <div className="col-xl-3 col-md-6">
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-globe-europe-africa"></i></div>
                                        <h4 className="title"><a href="" className="stretched-link">{t("eu_reg_comp")}</a></h4>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-chat-dots"></i></div>
                                        <h4 className="title"><a href="" className="stretched-link">{t("labels_for_multiple_langs")}</a></h4>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-wrench"></i></div>
                                        <h4 className="title"><a href="" className="stretched-link">{t("qr_modification")}</a></h4>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-keyboard"></i></div>
                                        <h4 className="title"><a href="" className="stretched-link">{t("friendly_interface")}</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* About Section */}
                <section id="about" className="about section">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                                <p className="who-we-are">{t("about_product")}</p>
                                <h3>{t("qr_subtitle_3")}</h3>
                                <p>
                                    {t("qr_about_t1")}
                                </p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>{t("qr_about_t2")}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{t("qr_about_t3")}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{t("qr_about_t4")}</span></li>
                                </ul>
                                <a href="https://www.fao.org/faolex/results/details/en/c/LEX-FAOC134451/#:~:text=This%20Regulation%20establishes%20a%20common,the%20markets%20in%20fishery%20and" className="read-more"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                            </div>

                            <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
                                <div className="row gy-4">
                                    <div className="col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: '10px', }}>
                                        <img src={aboutCompany1} className="img-fluid" alt="About Company 1" />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row gy-4">
                                            <div className="col-lg-12">
                                                <img src={aboutCompany2} className="img-fluid" alt="About Company 2" />
                                            </div>
                                            <div className="col-lg-12">
                                                <img src={aboutCompany3} className="img-fluid" alt="About Company 3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Services Section */}
                <section id="services" className="services section">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>{t("services")}</h2>
                        <div><span>{t("check_our")}</span> <span className="description-title">{t("services")}</span></div>
                    </div>

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-easel"></i>
                                    </div>
                                    <a href="https://tinyurl.com/4p99zes8/23/123310/" className="stretched-link">
                                        <h3>{t("label_example")}</h3>
                                    </a>
                                    <p>{t("label_example_text")}</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi-qr-code-scan"></i>
                                    </div>
                                    <a>
                                        <NavLink className="stretched-link" to="/services?section=generating" />
                                        <h3>{t("generating_qr")}</h3>
                                    </a>
                                    <p>{t('generating_qr_text')}</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-wrench"></i>
                                    </div>
                                    <a>
                                        <NavLink className="stretched-link" to="/services?section=creating" />
                                        <h3>{t('creating_a_digital_label')}</h3>
                                    </a>
                                    <p>{t('creating_a_digital_label_txt')}</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-pc"></i>
                                    </div>
                                    <a>
                                        <NavLink className="stretched-link" to="/services?section=qr" />
                                        <h3>{t('qr_hosting')}</h3>
                                    </a>
                                    <p>{t('qr_hosting_text')}</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-filetype-doc"></i>
                                    </div>
                                    <a>
                                        <NavLink className="stretched-link" to="/services?section=regulatory" />
                                        <h3>{t('regulatory_compl')}</h3>
                                    </a>
                                    <p>{t('regulatory_compl_txt')}</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-chat-square-text"></i>
                                    </div>
                                    <a>
                                        <NavLink className="stretched-link" to="/services?section=support" />
                                        <h3>{t('multiple_langs')}</h3>
                                    </a>
                                    <p>{t('multiple_langs_text')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Features Section */}
                <section id="features" className="features section light-background">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>{t('features')}</h2>
                        <div><span>{t('check_our')}</span> <span className="description-title">{t('features')}</span></div>
                    </div>

                    <div className="container">
                        <div className="row gy-5 justify-content-between">
                            <div className="col-xl-5" data-aos="zoom-out" data-aos-delay="100">
                                <img src={heroImg} className="img-fluid" alt="Features" />
                            </div>
                            <div className="col-xl-6 d-flex">
                                <div className="row align-self-center gy-4">
                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check"></i>
                                            <h3>{t('f1')}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check"></i>
                                            <h3>{t('f2')}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check"></i>
                                            <h3>{t('f3')}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check"></i>
                                            <h3>{t('f4')}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="700">
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check"></i>
                                            <h3>{t('f5')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Pricing Section */}
                <section id="pricing" className="pricing section">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>{t('pricing')}</h2>
                        <div><span>{t('check_our')}</span> <span className="description-title">{t('pricing')}</span></div>
                    </div>

                    <div className="container">
                        <div className="row gy-4" style={{ justifyContent: "center" }}>
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <div className="pricing-tem">
                                    <span className="featured">Featured</span>
                                    <h3 style={{ color: '#0dcaf0' }}>Small Plan</h3>
                                    <div className="price"><sup>€</sup>-<span> / mo</span></div>
                                    <div className="icon">
                                        <i className="bi bi-send" style={{ color: '#0dcaf0' }}></i>
                                    </div>
                                    <ul>
                                        <li>{t('p1_10')}</li>
                                        <li>{t('p2_s')}</li>
                                        <li>{t('p3')}</li>
                                        <li>{t('p4')}</li>
                                        <li className="na">{t('p5')}</li>
                                        <li className="na">{t('p6')}</li>
                                    </ul>
                                    <a href="#contact" className="btn-buy">{t('buy_now')}</a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                                <div className="pricing-tem">
                                    <h3 style={{ color: '#fd7e14' }}>Medium Plan</h3>
                                    <div className="price"><sup>€</sup>-<span> / mo</span></div>
                                    <div className="icon">
                                        <i className="bi bi-airplane" style={{ color: '#fd7e14' }}></i>
                                    </div>
                                    <ul>
                                        <li>{t('p1_40')}</li>
                                        <li>{t('p2_m')}</li>
                                        <li>{t('p3')}</li>
                                        <li>{t('p4')}</li>
                                        <li>{t('p6')}</li>
                                        <li className="na">{t('p5')}</li>

                                    </ul>
                                    <a href="#contact" className="btn-buy">{t('buy_now')}</a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                                <div className="pricing-tem">
                                    <h3 style={{ color: '#0d6efd' }}>Large Plan</h3>
                                    <div className="price"><sup>€</sup>-<span> / mo</span></div>
                                    <div className="icon">
                                        <i className="bi bi-rocket" style={{ color: '#0d6efd' }}></i>
                                    </div>
                                    <ul>
                                        <li>{t('p1_40p')}</li>
                                        <li>{t('p2_l')}</li>
                                        <li>{t('p3')}</li>
                                        <li>{t('p4')}</li>
                                        <li>{t('p6')}</li>
                                        <li>{t('p5')}</li>

                                    </ul>
                                    <a href="#contact" className="btn-buy">{t('buy_now')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* FAQ Section */}
                <section id="faq" className="faq section light-background">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="content px-xl-5">
                                    <h3><span>{t('fa')} </span><strong>{t('q')}</strong></h3>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <div className="faq-container">
                                    <div className="faq-item faq-active">
                                        <h3><span className="num">1.</span> <span>{t('q1')}</span></h3>
                                        <div className="faq-content">
                                            <p>{t('a1')}</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3><span className="num">2.</span> <span>{t('q2')}</span></h3>
                                        <div className="faq-content">
                                            <p>{t('a2')}</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3><span className="num">3.</span> <span>{t('q3')}</span></h3>
                                        <div className="faq-content">
                                            <p>{t('a3')}</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3><span className="num">4.</span> <span>{t('q4')}</span></h3>
                                        <div className="faq-content">
                                            <p>{t('a4')}</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3><span className="num">5.</span> <span>{t('q5')}</span></h3>
                                        <div className="faq-content">
                                            <p>{t('a5')}</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Contact Section */}
                <section id="contact" className="contact section">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>{t('contact')}</h2>
                        <div><span>{t('need_help_qm')}</span> <span className="description-title">{t('contact_us')}</span></div>
                    </div>

                    <div className="container" data-aos="fade" data-aos-delay="100">
                        <div className="row gy-4">
                            <div className="col-lg-4">

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h3>Email</h3>
                                        <p>danijel@ordonovus.com</p>

                                    </div>
                                </div>

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h3>Email</h3>
                                        <p>kristian@ordonovus.com</p>

                                    </div>
                                </div>

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-telephone flex-shrink-0"></i>
                                    <div>
                                        <h3>{t('call_us')}</h3>
                                        <p>+38592 286 2492</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <h3>{t('bt1')}</h3>
                                <p>{t('bt2')}</p>

                            </div>
                            {/*

                                <div className="col-lg-8">
                                    <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                                        <div className="row gy-4">
                                            <div className="col-md-6">
                                                <input type="text" name="name" className="form-control" placeholder="Your Name" required="" />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="email" className="form-control" name="email" placeholder="Your Email" required="" />
                                            </div>

                                            <div className="col-md-12">
                                                <input type="text" className="form-control" name="subject" placeholder="Subject" required="" />
                                            </div>

                                            <div className="col-md-12">
                                                <textarea className="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                                            </div>

                                            <div className="col-md-12 text-center">
                                                <div className="loading">Loading</div>
                                                <div className="error-message"></div>
                                                <div className="sent-message">Your message has been sent. Thank you!</div>
                                                <button type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>*/}
                        </div>
                    </div>
                </section>
            </main>

            <footer id="footer" className="footer light-background">
                <div className="container">
                    <div className="copyright text-center">
                        <p>© <span>Copyright</span> <strong className="px-1 sitename">Ordo Novus</strong> <span>All Rights Reserved</span></p>
                    </div>
                    <div className="social-links d-flex justify-content-center">
                        <a href=""><i className="bi bi-twitter-x"></i></a>
                        <a href=""><i className="bi bi-facebook"></i></a>
                        <a href=""><i className="bi bi-instagram"></i></a>
                        <a href=""><i className="bi bi-linkedin"></i></a>
                    </div>
                    <div className="credits">
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
            </footer>

            <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short"></i>
            </a>



            {/* <div id="preloader"></div> */}
            {/* External Scripts */}
            <script src="./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
            <script src="./assets/vendor/php-email-form/validate.js"></script>
            <script src="./assets/vendor/aos/aos.js"></script>
            <script src="./assets/vendor/glightbox/js/glightbox.min.js"></script>
            <script src="./assets/js/main.js"></script>
        </div>
    );

}

export default HomePage;
